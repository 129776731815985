import React, { useEffect, useRef } from 'react';
import {
    Form,
    Button,
    Header,
    FormField,
    Input,
    Container,
    SpaceBetween,
    Box,
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import messages from './ProfilePage.messages';
import headerMessages from '../ClassHeader/ClassHeader.messages';
import { useUser } from '../utils/user';
import { performFetch } from '../sagas';
import { useQuery } from '../utils';
import { injectIntl } from 'react-intl';
import ClassErrorModal from '../ClassErrorModal/ClassErrorModal';
import { useHistory } from 'react-router-dom';
import './ProfilePage.scss';

const nameRegex = /^[^_!?/\\+=@#%^&*(){}|~<>;[\]$"]*$/;
// True iff a name is truthy, does not contain forbidden characters, and is not exclusively whitespace
const isNameValid = name => !!name?.trim() && nameRegex.test(name);

/**
 * Displays a profile page for students to interact with their PII attributes. They may update them and will be
 * navigated back to the previous page.
 */
export const ProfilePage = ({
    intl: { formatMessage },
    attributes: { firstName, lastName },
    globals = window,
}) => {
    const storage = globals.sessionStorage;

    const [firstNameValue, setFirstNameValue] = useState(firstName);
    const [lastNameValue, setLastNameValue] = useState(lastName);
    let firstNameRef = useRef();
    let lastNameRef = useRef();

    const [isFirstNameWarningVisible, setFirstNameWarningVisible] = useState(
        false
    );
    const [isLastNameWarningVisible, setLastNameWarningVisible] = useState(
        false
    );

    let saveButtonLabel = formatMessage(messages.saveAndContinueButtonLabel);
    let headerLabel = formatMessage(messages.headerLabel);
    let description = formatMessage(messages.description);

    if (firstName && lastName) {
        saveButtonLabel = formatMessage(messages.saveButtonLabel);
        headerLabel = formatMessage(messages.updateProfileHeaderLabel);
        description = formatMessage(messages.updateProfileDescription);
    }

    // Sets or clears warning before saving the value
    const setNameValue = (value, setNameValue, setWarningVisible) => {
        if (isNameValid(value)) {
            setWarningVisible(false);
        } else {
            setWarningVisible(true);
        }

        setNameValue(value);
    };

    const { idToken } = useUser();

    const history = useHistory();
    const {
        fetchDispatch: putUserAttributesDispatch,
        state: putUserAttributesState,
    } = useQuery('putUserAttributes');
    const {
        data: putUserAttributesData,
        error: putUserAttributesError,
        loading: putUserAttributesLoading,
    } = putUserAttributesState;

    // Navigates to the previous page once the attributes have been updated.
    useEffect(() => {
        if (putUserAttributesData) {
            storage.setItem('bannerVisible', 'true');
            storage.setItem('bannerType', 'success');
            storage.setItem(
                'bannerMessage',
                formatMessage(messages.updateSuccessMessage)
            );
            history.goBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [putUserAttributesData, history]);

    // Displays a warning if either name is invalid. If both are valid, it updates the attributes.
    const putUserAttributesHandler = async () => {
        const firstNameValid = isNameValid(firstNameValue);
        const lastNameValid = isNameValid(lastNameValue);
        if (!firstNameValid) {
            setFirstNameWarningVisible(true);
            firstNameRef.focus();
        }
        if (!lastNameValid) {
            setLastNameWarningVisible(true);
            if (firstNameValid) {
                lastNameRef.focus();
            }
        }

        if (firstNameValid && lastNameValid) {
            await performFetch(putUserAttributesDispatch, {
                params: {
                    method: 'POST',
                    body: {
                        firstName: firstNameValue,
                        lastName: lastNameValue,
                    },
                },
                api: putUserAttributesState,
                idToken,
            });
        }
    };

    return (
        <Container className="profile-form-container">
            <Helmet>
                <title>
                    {`${formatMessage(
                        headerMessages.profilePageLabel
                    )} - Amazon Web Services (AWS)`}
                </title>
            </Helmet>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        {firstName && lastName ? (
                            <Button
                                data-testid="profile-cancel-button"
                                onClick={() => history.goBack()}
                            >
                                {formatMessage(messages.cancelButtonLabel)}
                            </Button>
                        ) : null}
                        <Button
                            data-testid="profile-save-button"
                            variant="primary"
                            onClick={putUserAttributesHandler}
                            loading={putUserAttributesLoading}
                        >
                            {saveButtonLabel}
                        </Button>
                    </SpaceBetween>
                }
                header={<Header variant="h1">{headerLabel}</Header>}
            >
                <SpaceBetween direction="vertical" size="l">
                    <Box margin={'n'} padding={'n'}>
                        {description}
                    </Box>

                    <FormField
                        data-testid="profile-first-name-field"
                        label={formatMessage(messages.firstNameLabel)}
                        errorText={
                            isFirstNameWarningVisible &&
                            formatMessage(messages.invalidFirstName)
                        }
                    >
                        <Input
                            data-testid="profile-first-name"
                            value={firstNameValue}
                            ref={r => (firstNameRef = r)}
                            onChange={({ detail }) =>
                                setNameValue(
                                    detail.value,
                                    setFirstNameValue,
                                    setFirstNameWarningVisible
                                )
                            }
                        />
                    </FormField>
                    <FormField
                        data-testid="profile-last-name-field"
                        label={formatMessage(messages.lastNameLabel)}
                        errorText={
                            isLastNameWarningVisible &&
                            formatMessage(messages.invalidLastName)
                        }
                    >
                        <Input
                            data-testid="profile-last-name"
                            value={lastNameValue}
                            ref={r => (lastNameRef = r)}
                            onChange={({ detail }) =>
                                setNameValue(
                                    detail.value,
                                    setLastNameValue,
                                    setLastNameWarningVisible
                                )
                            }
                        />
                    </FormField>
                </SpaceBetween>
            </Form>
            <ClassErrorModal
                error={putUserAttributesError}
                customMessages={messages}
            />
        </Container>
    );
};

export default injectIntl(ProfilePage);
