import React, { useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import './ClassBanner.scss';
import { Button } from '@amzn/awsui-components-react';
import { useApiClient } from '../utils/hooks/useApiClient';
import _get from 'lodash/get';
import messages from './ClassBanner.messages';
import { useQuery } from 'react-query';
import { useFlags } from '../utils/hooks/useFlags';
import {
    joinTrainingErrors,
    buildClassFlashBar,
} from '../ClassPage/ClassPage.utils';

export const JoinTrainingButton = ({
    locationData,
    classroomId,
    setFlashBar,
}) => {
    const bffClient = useApiClient();
    const [isFetchingUrl, isFetchingUrlSet] = useState(false);
    const { formatMessage } = useIntl();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleErrorMessage = message =>
        setFlashBar(
            buildClassFlashBar('error', setFlashBar, formatMessage(message))
        );

    useQuery(
        ['getMeeting', classroomId],
        ({ queryKey: [, classId] }) => bffClient.getMeeting(classId),
        {
            enabled: isFetchingUrl,
            onError: error => {
                isFetchingUrlSet(false);
                setButtonDisabled(true);
                const errCause = error.cause;
                console.error('JoinURL fetching failed', error);
                switch (errCause) {
                    case joinTrainingErrors.learningActivityNotFoundError:
                        handleErrorMessage(
                            messages.learningActivityNotFoundError
                        );
                        break;
                    case joinTrainingErrors.meetingNotFoundError:
                        handleErrorMessage(messages.meetingNotFoundError);
                        break;
                    default:
                        handleErrorMessage(messages.otherError);
                }
            },
            onSuccess: data => {
                isFetchingUrlSet(false);
                let url = _get(data, 'joinURL');
                window.open(url, '_blank').focus();
            },
            refetchInterval: `0`,
        }
    );

    const joinTrainingOnClick = () => {
        setFlashBar([]);
        if (locationData.source === 'LADS') {
            isFetchingUrlSet(true);
        } else {
            window.open(locationData.virtualUrl, '_blank').focus();
        }
    };

    return (
        <Button
            data-testid="join-training-button"
            variant="primary"
            iconName="external"
            iconAlign="right"
            loading={isFetchingUrl}
            onClick={joinTrainingOnClick}
            disabled={buttonDisabled}
        >
            {useIntl().formatMessage(messages.joinTrainingButton)}
        </Button>
    );
};

export const ClassBanner = ({
    intl: { formatMessage },
    title,
    breadcrumbItems,
    locationData,
    classroomId,
    isStudentLandingPage = false,
    setFlashBar,
}) => {
    const history = useHistory();
    const onClickOverride = e => {
        e.preventDefault();
        history.push(e.detail.href);
    };
    const hasBreadcrumbs = breadcrumbItems?.length > 0;

    const featureFlags = useFlags();
    const { aurousIntegration = false } = featureFlags || {};

    return (
        <div
            className={`banner-header ${
                hasBreadcrumbs ? 'with-breadcrumbs' : ''
            }`}
        >
            {hasBreadcrumbs && (
                <div className="banner-breadcrumbs awsui-polaris-dark-mode">
                    <BreadcrumbGroup
                        items={breadcrumbItems}
                        onClick={onClickOverride}
                        ariaLabel={formatMessage(messages.breadcrumbsLabel)}
                    />
                </div>
            )}

            {isStudentLandingPage ? (
                <div
                    data-test="landingpage-title"
                    className="landingpage-banner-content"
                >
                    <h1 className="landingpage-banner-title awsui-text-large">
                        {title}
                    </h1>
                </div>
            ) : (
                <div data-test="class-title" className="banner-content">
                    <h1 className="banner-title awsui-text-large">{title}</h1>
                    {aurousIntegration &&
                        locationData &&
                        locationData.locationType === 'virtual' && (
                            <div className="banner-content-button">
                                <JoinTrainingButton
                                    locationData={locationData}
                                    classroomId={classroomId}
                                    setFlashBar={setFlashBar}
                                />
                            </div>
                        )}
                </div>
            )}
        </div>
    );
};

export default injectIntl(ClassBanner);
