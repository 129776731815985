import React, { useEffect, useState, useRef, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import _get from 'lodash/get';
import { Modal, Button, Grid } from '@amzn/awsui-components-react';

import { isBeforeTimestamp, adjustUnixTimestamp, useQuery } from '../utils';
import { performFetch } from '../sagas';
import { SectionTitle } from '../Section';
import messages from './ClassLabListHeader.messages';
import { useUser } from '../utils/user';
import './ClassLabListHeader.scss';

const ClassLabListHeader = ({
    intl: { formatMessage },
    startsOn,
    classId,
    books,
}) => {
    const hasTheClassStarted = isBeforeTimestamp(adjustUnixTimestamp(startsOn));
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [guideType, setGuideType] = useState('');
    const [ltiParams, setLtiParams] = useState({});
    const { fetchDispatch, state: studentGuideState } = useQuery('getGuides');
    const {
        data: studentGuideData,
        loading: studentGuideLoading,
        error: studentGuideError,
    } = studentGuideState;

    const { idToken } = useUser();

    const onClickStudentGuide = async (ev, type) => {
        ev.preventDefault();
        setGuideType(type);
        performFetch(fetchDispatch, {
            params: {
                method: 'GET',
                path: `/${classId}`,
            },
            api: studentGuideState,
            idToken,
        });
    };

    const formRef = useRef();

    const guideDataExists = data => data && Object.keys(data).length;

    const GuideBtn = ({ guideType, btnText }) => (
        <Button
            className="guide-btn"
            iconAlign="right"
            iconName="external"
            data-test={`${guideType}-guide-btn`}
            ariaLabel={formatMessage(messages.guideButtonAriaLabel, {
                buttonLabel: formatMessage(btnText),
            })}
            href="#"
            onClick={ev => onClickStudentGuide(ev, guideType)}
            disabled={
                !hasTheClassStarted ||
                studentGuideLoading ||
                !_get(books, `${guideType}Guide.vbId`)
            }
        >
            {formatMessage(btnText)}
        </Button>
    );

    useEffect(() => {
        if (studentGuideError) {
            setErrorModalOpen(true);
        } else if (guideDataExists(studentGuideData)) {
            // cjab: i don't like this, but jest has some weird behaviors
            // with React hooks where the guideType state doesn't propagate
            // properly even if it works fine in the browser. Also, it's
            // (arguably) safer to default to return something
            // rather than nothing
            const which = guideType || 'student';
            const params = _get(studentGuideData, which, {});
            setLtiParams(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentGuideData, studentGuideError]);

    useEffect(() => {
        if (Object.keys(ltiParams).length) {
            formRef.current.submit();
        }
    }, [ltiParams]);

    return (
        <Fragment>
            <div className="awsui-grid awsui-util-pb-l">
                <SectionTitle>
                    {formatMessage(messages.sectionTitle)}
                </SectionTitle>

                <Grid
                    disableGutters
                    gridDefinition={[
                        { colspan: { default: 12, xs: 6 } },
                        { colspan: { default: 12, xs: 6 } },
                    ]}
                >
                    <p>{formatMessage(messages.labsInfo)}</p>
                    <div className="class-lab-list-header__btns">
                        <GuideBtn
                            guideType="lab"
                            btnText={messages.labGuideButtonText}
                        />
                        <GuideBtn
                            guideType="student"
                            btnText={messages.callToActionButtonText}
                        />
                    </div>
                </Grid>
            </div>
            <form
                action="https://bc.vitalsource.com/books/book"
                method="POST"
                target="_blank"
                id="student-guide-form"
                rel="noopener noreferrer"
                ref={formRef}
            >
                {ltiParams &&
                    Object.keys(ltiParams).map(v => (
                        <input
                            key={v}
                            name={v}
                            value={ltiParams[v]}
                            type="hidden"
                        />
                    ))}
            </form>
            <Modal
                visible={errorModalOpen}
                header={formatMessage(messages.studentGuideErrorTitle)}
                closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
                onDismiss={() => setErrorModalOpen(false)}
                footer={
                    <Button
                        variant="primary"
                        onClick={() => setErrorModalOpen(false)}
                    >
                        {formatMessage(
                            messages.studentGuideErrorModalActionButton
                        )}
                    </Button>
                }
            >
                {formatMessage(messages.studentGuideErrorContent)}
            </Modal>
        </Fragment>
    );
};

export default injectIntl(ClassLabListHeader);
